import { createBrowserRouter, useNavigate, } from "react-router-dom";
import { useEffect } from "react";
import { allpath } from "./allPath";
import MainLayout from "../layouts/MainLayout";
import ScrollToTop from "../components/ScrollToTop";

const Error404 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div>
    </div>
  );
};

export const router = createBrowserRouter([
  {
    element: (
      <>
        <ScrollToTop /> {/* Include ScrollToTop here */}
        <MainLayout />
      </>
    ),
    errorElement: <Error404 />,
    children: allpath.map((item) => ({
      path: item.path,
      element: item.element,
    })),
  },
]);