import React from 'react'
import SwiperDemo from '../components/Swiper'
import { build, developers, our_process } from '../data/data'
import { Grid2 } from '@mui/material'
import { ArrowRightSharp, East } from '@mui/icons-material'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div className='px-5 h-100'>
      <Grid2 container className=' justify-content-between align-items-center d-flex' style={{ height: 'calc(100vh - 68px)' }} >
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 65, maxWidth: 350 }}>Innovate Your Tomorrow, Today.</h1>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div style={{ maxWidth: 500 }}>
            <p className='size28 weight700 mt-5 pt-5'>
              Tech With Talent. Fast and Flexible
            </p>
            <p className='size22 weight500'>
              Accelerating enterprise intelligence with swift, adaptable software solution.
            </p>
          </div>
        </Grid2>
      </Grid2>
      {/* <Grid2 container className='h-75  w-100 align-items-start d-flex mt-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 30 }}>Our Process</h1>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div className='w-100'>
            <div style={{ maxWidth: 500 }} className=''>
              <SwiperDemo data={our_process} />
            </div>
          </div>
        </Grid2>
      </Grid2> */}
      <Grid2 container className='justify-content-between w-100 align-items-start d-flex mb-5 pb-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 50, fontSize: 30 }}>From concept to launch, rely on our dedicated team to bring your digital business to life.</h1>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div className=''>
            <div style={{ maxWidth: 800 }} className='d-flex flex-wrap gap-5'>
              {
                build.map((item) => (
                  <div style={{ maxWidth: 300 }}>
                    <h1 className='weight600'>{item.title}</h1>
                    <p className='size22 weight500 mt-3'>
                      {item.desc}
                    </p>
                  </div>
                ))
              }
            </div>
          </div>
        </Grid2>
      </Grid2>
      <Grid2 container className='justify-content-between w-100 align-items-start d-flex mt-5 pt-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Team</h1>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div className=''>
            <div className='d-flex flex-wrap gap-5'>
              <p className='size22 weight500'>
                Our talented team brings vast expertise in multiple language delivering flawless product execution and striking designs that captivate audience. with specialists across SEO, graphic design, digital marketing, web and UI/UX design and expert developers, we're equipped to turn our vision into a standout digital experience. Let's bring your ideas to life together!
              </p>

              <Grid2 container spacing={3} className="w-100" >
                {/* {
                  developers.map((item) => (

                    <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }} className=" " >
                      <div className='d-flex justify-content-between align-items-center border-bottom py-3'>
                        <p className='size22 weight600 '>
                          {item.name}
                        </p>
                        <p className='size18 weight500 text-secondary'>
                          {item.role}
                        </p>
                      </div>
                    </Grid2>

                  ))
                } */}
              </Grid2>
            </div>
          </div>
        </Grid2>
      </Grid2>
      <Grid2 container className=' justify-content-between align-items-center d-flex' style={{ height: 'calc(100vh - 68px)' }} >
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 500, textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 65 }}>Let's build or improve your digital product</h1>
          <Link to={"/contact"} className='text-dark' style={{ textDecoration: 'none' }}>
            <div className='d-flex gap-2 align-items-center mt-5'>
              <p className='size22 weight600 '>
                CONTACT US!
              </p>
              <p>
                <East />
              </p>
            </div>
          </Link>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>

        </Grid2>
      </Grid2>
      <Footer />
    </div>
  )
}

export default Home
