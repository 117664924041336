import React, { useState } from 'react'
import { Grid2 } from '@mui/material'
import { East } from '@mui/icons-material'
import Footer from '../components/Footer'
import CustomInput from '../components/CustomInput'
import toast from 'react-hot-toast'
import { db } from '../configuration'
import { addDoc, collection } from 'firebase/firestore'

const Contact = () => {

  const [data, setData] = useState({})

  const handleChange = (name, value) => {
    setData((prev) => (
      {
        ...prev,
        [name]: value
      }
    ))
  }

  const handleSubmit = async () => {

    if (!data?.email || !data?.name || !data?.msg) {
      return toast.error("Please fill all required fields!")
    }

    toast('We Got Your Message, Thanks You', {
      icon: '😇',
    });

    try {
      const docRef = await addDoc(collection(db, "msgs"), data);
      console.log({data, docRef})
      setData({})
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  return (
    <div className='px-5 h-100'>
      <Grid2 container className=' justify-content-between align-items-start d-flex' style={{ marginTop: 170 }} >
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 105, marginBottom: 0 }}>Drop a Message.</h1>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div style={{ maxWidth: 500 }} className=''>
            {/* <p className='size28 weight700 '>
              We are here to help!
            </p>
            <p className='size22 weight500'>
              No matter whether you need help with a website, a mobile application, or an online store, we will be more than happy to lend you a hand.
            </p> */}

            <div className='mb-5'>
              <CustomInput
                label="HOW CAN WE HELP?"
                value={data.msg ? data.msg : ""}
                onChange={(e) => { handleChange("msg", e.target.value) }}
              />
              <CustomInput
                label="YOUR NAME"
                value={data.name ? data.name : ""}
                onChange={(e) => { handleChange("name", e.target.value) }}
              />
              <CustomInput
                label="YOUR EMAIL"
                value={data.email ? data.email : ""}
                onChange={(e) => { handleChange("email", e.target.value) }}
              />
              <CustomInput
                label="YOUR MOBILE NUMBER"
                value={data.mobile ? data.mobile : ""}
                onChange={(e) => { handleChange("mobile", e.target.value) }}
              />
              <div className='d-flex gap-2 align-items-center mt-5' style={{ width: 'fit-content' }} role='button' onClick={handleSubmit}>
                <p className='size22 weight600 '>
                  SEND
                </p>
                <p>
                  <East />
                </p>
              </div>
            </div>
          </div>
        </Grid2>
      </Grid2>

      <Grid2 container className='justify-content-between w-100 align-items-start d-flex my-2 pt-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Get in touch</h1>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div className='mb-5 pb-5'>
            <div className='d-flex flex-wrap gap-5'>
              <Grid2 container spacing={3} className="w-100" >
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>hello@gmail.com</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    No matter whether you need help with a website, a mobile application, or an online store, we will be more than happy to lend you a hand.
                  </p>

                  <div className='d-flex gap-2 align-items-center mt-5'>
                    <p className='size22 weight600 '>
                      SEND
                    </p>
                    <p>
                      <East />
                    </p>
                  </div>

                </Grid2>

              </Grid2>
            </div>
          </div>
        </Grid2>
      </Grid2>

      {/* <Grid2 container className=' justify-content-between align-items-center d-flex' style={{ height: 'calc(100vh - 68px)' }} >
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 500, textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 65 }}>Let's build or improve your digital product</h1>
          <div className='d-flex gap-2 align-items-center mt-5'>
            <p className='size22 weight600 '>
              CONTACT US
            </p>
            <p>
              <East />
            </p>
          </div>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>

        </Grid2>
      </Grid2> */}

      <Footer />
    </div>
  )
}

export default Contact
