import React from 'react'
import { Grid2 } from '@mui/material'

const Team = () => {
  return (
    <div className='px-5 h-100'>
      <Grid2 container className=' justify-content-between align-items-center d-flex' style={{ height: 'calc(100vh - 68px)' }} >
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 65 }}>Team</h1>
        </Grid2>
      </Grid2>
    </div>
  )
}

export default Team
