import React, { useEffect, useState } from 'react'
import { tabs } from '../data/data'
import { Link, useLocation } from 'react-router-dom'
import { Cancel, CancelOutlined, Close, MenuOpen } from '@mui/icons-material'

const Header = () => {

    const [open, setOpen] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setOpen(false)
    }, [location.pathname])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='px-5 py-3 d-flex justify-content-between align-items-center border'>
            <Link to={"/"} className='text-dark' style={{ textDecoration: 'none' }}>
                <img
                    src={require("../assets/images/logo.webp")}
                    style={{ maxHeight: 50 }}
                />
            </Link>

            <div className='gap-5 d-flex header-tabs'>
                {
                    tabs.map((item) => (
                        <div style={{ position: 'relative' }}>
                            <Link to={item.link} className='text-dark hoverable-header-menu' style={{ textDecoration: 'none' }}>
                                <p className='size16 weight600 text-secondary' style={{ cursor: 'pointer' }}>{item.name}</p>
                            </Link>

                            <div style={{ position: 'absolute', top: 30 }} className='px-3 py-2 border bg-white header-menu'>
                                <p className='size16 weight600 text-secondary' style={{ cursor: 'pointer' }}>{item.name}</p>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='header-menu' style={{ cursor: 'pointer' }} onClick={() => { setOpen(!open) }}>
                <MenuOpen />
            </div>


            <div className='sidebar-animated' style={{ height: '100vh', width: open ? '100vw' : 0, background: 'rgba(0,0,0,1)', position: 'fixed', top: 0, right: 0, overflow: 'hidden', opacity: open ? 1 : 0 }}>
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: 20, right: 50, cursor: 'pointer', color: 'white' }} onClick={() => { setOpen(!open) }}>
                        <Close />
                    </div>
                </div>
                <div className='gap-3 d-flex flex-column pl-3 text-white p-5 mt-5'>
                    {
                        tabs.map((item) => (
                            <Link to={item.link} className='text-dark' style={{ textDecoration: 'none' }}>
                                <p className='size30 weight700 text-white' style={{ cursor: 'pointer' }}>{item.name}</p>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Header
