import React from 'react'
import { Grid2 } from '@mui/material'
import { East } from '@mui/icons-material'
import Footer from '../components/Footer'

const About = () => {
  return (
    <div className='px-5 h-100'>
      <Grid2 container className=' justify-content-between align-items-center d-flex mt-5 pb-5 pt-5'  >
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }} className="mt-5">
          <h1 style={{ textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 65 }}>On-time. On-</h1>
          <h1 style={{ textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 65 }}>budget. On-</h1>
          <h1 style={{ textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 65 }}>point.</h1>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div style={{ maxWidth: 500 }}>
            <p className='size22 weight500 mt-5 pt-4'>
              Delivering software solutions to build intelligent enterprises with speed and agility.
            </p>
          </div>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 4 }}>
          <div className='d-flex gap-2 align-items-center mt-5'>
            <p className='size22 weight600 '>
              CONTACT US
            </p>
            <p>
              <East />
            </p>
          </div>
        </Grid2>
      </Grid2>

      <Grid2 container className='justify-content-between w-100 align-items-start d-flex my-5 pt-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>The good stuff we strongly believe in</h1>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div className=''>
            <div className='d-flex flex-wrap gap-5'>
              <Grid2 container spacing={3} className="w-100" >
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Transparency</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    A UX Designer is going to outline the structure of your product. They will do so by designing a series of wireframes for each section and feature.
                  </p>

                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Quality</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    To capture users’ attention and retain them, you need a digital product that is not only functional, but also visually appealing.
                  </p>
                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Respectfulness</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    A UX Designer is going to outline the structure of your product. They will do so by designing a series of wireframes for each section and feature.
                  </p>

                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Inclusivity</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    To capture users’ attention and retain them, you need a digital product that is not only functional, but also visually appealing.
                  </p>
                </Grid2>
              </Grid2>
            </div>
          </div>
        </Grid2>
      </Grid2>

      <Grid2 container className=' justify-content-between align-items-center d-flex' style={{ height: 'calc(100vh - 68px)' }} >
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
          <div className='p-5'>
            <img
              src={require("../assets/images/img1.png")}
              className='w-75 text-center'
            />
          </div>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
          <h1 style={{ maxWidth: 500, textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 65 }}>We are always on the lookout for new talent!</h1>
          <div className='d-flex gap-2 align-items-center mt-5'>
            <p className='size22 weight600 '>
              JOIN OUR TEAM
            </p>
            <p>
              <East />
            </p>
          </div>
        </Grid2>
      </Grid2>

      <Grid2 container className='justify-content-between w-100 align-items-start d-flex my-5 py-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Why our clients work with us</h1>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div className=''>
            <div className='d-flex flex-wrap gap-5'>
              <Grid2 container spacing={3} className="w-100" >
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Solution Provider</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    A UX Designer is going to outline the structure of your product. They will do so by designing a series of wireframes for each section and feature.
                  </p>

                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Focused on results</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    To capture users’ attention and retain them, you need a digital product that is not only functional, but also visually appealing.
                  </p>
                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Full cycle development</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    A UX Designer is going to outline the structure of your product. They will do so by designing a series of wireframes for each section and feature.
                  </p>

                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Warranty period</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    To capture users’ attention and retain them, you need a digital product that is not only functional, but also visually appealing.
                  </p>
                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>We go extra mile</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    To capture users’ attention and retain them, you need a digital product that is not only functional, but also visually appealing.
                  </p>
                </Grid2>
              </Grid2>
            </div>
          </div>
        </Grid2>
      </Grid2>

      <Footer />
    </div>
  )
}

export default About
