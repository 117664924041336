import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import OurProcess from "../pages/OurProcess";
import Project from "../pages/Project";
import Team from "../pages/Team";

export const allpath = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/our-process",
      element: <OurProcess />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/team",
      element: <Team />,
    },
    {
      path: "/project",
      element: <Project />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
]