export const tabs = [
    { name: "OUR PROCESS", link: "/our-process" },
    { name: "WHAT WE DO", link: "/" },
    // { name: "ABOUT", link: "/about" },
    // { name: "OUR TEAM", link: "/team" },
    { name: "PROJECT", link: "/project" },
    { name: "CONTACTS", link: "/contact" },
]

export const our_process = [
    { title: "01. Discovery", desc: "At this stage, we dive into the business processes and gather the information that will be the basis for the next steps." },
    { title: "02. Design", desc: "We design and create stunning UI/UX according to popular patterns and iOS/Android style guides." },
    { title: "03. Development", desc: "At this stage, all the pieces come together under a well-managed mobile or web development." },
    { title: "04. Testing", desc: "Our Quality Assurance team catches any bug before deploying the product and lets us focus on high quality during each development cycle." },
    { title: "05. Release and Warranty", desc: "We always provide you with a warranty period. Simply put, we provide a warranty because we are truly confident in our work." },
    { title: "06. Support", desc: "Finally, we remain a technical partner for as long as the client needs us." },
]

export const build = [
    { title: "Discovery Session Exploration & Strategy", desc: "We begin by understanding your business, challenges, and goals. Together, we’ll outline a clear strategy tailored to your needs. " },
    { title: "Product Design", desc: "Our team will design a user-friendly interface that merges creativity and functionality to deliver a seamless digital experience. " },
    { title: "Development & Build", desc: "From coding to integration, we’ll transform designs into a robust, high-performing digital solution ready to meet your needs." },
    { title: "Launch & Scale", desc: "We’ll ensure a smooth launch and provide the tools and insights you need to scale your product and grow your business." },
]

export const developers = [
    { name: "Robin", role: "Web Developer" },
    { name: "Alice", role: "Web Developer" },
    { name: "Bob", role: "Web Developer" },
    { name: "Charlie", role: "Web Developer" },
    { name: "David", role: "Web Developer" },
    { name: "Eve", role: "Web Developer" },
    { name: "Frank", role: "Web Developer" },
    { name: "Grace", role: "Web Developer" },
    { name: "Hannah", role: "Web Developer" },
    { name: "Isaac", role: "Web Developer" }
];

export const discovery_scopping = [
    "Collaborative Workshops",
    "Distinctive Value Proposition",
    "User Persona"
];

export const ux_research = [
];

export const projects = [
    {img: require("../assets/images/img2.png"), title: 'INFORMATION TECHNOLOGY', desc: "Enterprise website for a cybersecurity market leader"},
    {img: require("../assets/images/img3.png"), title: 'INFORMATION TECHNOLOGY', desc: "Enterprise website for a cybersecurity market leader"},
    {img: require("../assets/images/img4.png"), title: 'INFORMATION TECHNOLOGY', desc: "Enterprise website for a cybersecurity market leader"},
    {img: require("../assets/images/img5.png"), title: 'INFORMATION TECHNOLOGY', desc: "Enterprise website for a cybersecurity market leader"},
    {img: require("../assets/images/img6.png"), title: 'INFORMATION TECHNOLOGY', desc: "Enterprise website for a cybersecurity market leader"},
    {img: require("../assets/images/img7.png"), title: 'INFORMATION TECHNOLOGY', desc: "Enterprise website for a cybersecurity market leader"},
];

export const projectsNew = [
    {count: 200, title: "Total Projects"},
    {count: 250, title: "Total Clients"},
    {count: 15, title: "Total Years"},
];