import React from 'react'
import { RouterProvider } from "react-router-dom"
import { router } from './routes/route'
import "./App.css"
import { Toaster } from 'react-hot-toast'

function App() {
  // console.log = function() {}
  // console.warn = function() {}

  return (
    <div>
      <RouterProvider router={router} />
      <Toaster />
    </div>
  )
}

export default App
