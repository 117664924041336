import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation(); // Get the current pathname

    useEffect(() => {
        setTimeout(() => {
            alert("call")
            window.scrollTo(0, 0); // Scroll to the top when the pathname changes
        }, 5000);
        
    }, [pathname]); // Add pathname to the dependency array

    return null; // This component does not render anything
};

export default ScrollToTop;
