// Import necessary modules from React and Swiper
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles


const SwiperDemo = ({ data }) => {

    const [currentIndex, setCurrentIndex] = useState(0)
    const handleSlideChange = (swiper) => {
        setCurrentIndex(swiper.activeIndex); // Update the current index
    };

    console.log({currentIndex})


    return (
        <div className='w-100 h-100 p-3'>
            <Swiper
                onSlideChange={handleSlideChange}
                style={{ height: '100%' }} // Set height for the Swiper
                onSwiper={(swiper) => console.log({swiper})}
                autoplay={true}
            >
                {
                    data.map((item) => (
                        <SwiperSlide style={{ display: 'flex', flexDirection: 'column' }}>
                            <h1 className='weight600'>
                                {item.title}
                            </h1>
                            <p className='size24 weight500 mt-3'>
                                {item.desc}
                            </p>
                        </SwiperSlide>
                    ))
                }
            </Swiper>

            <div className='d-flex gap-3 mt-5'>
                {
                    data.map((_, index) => (
                        <div style={{ height: 15, width: 15, borderRadius: 100, backgroundColor: index == currentIndex ? 'black' : 'white', border: '1px solid gray' }}></div>
                    ))
                }
            </div>
        </div>
    );
};

export default SwiperDemo;