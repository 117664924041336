import React from 'react'
import { Grid2 } from '@mui/material'
import { developers, discovery_scopping, ux_research } from '../data/data'
import Footer from '../components/Footer'

const OurProcess = () => {
  return (
    <div className='px-5 h-100'>
      <Grid2 container className=' justify-content-between align-items-center d-flex mt-5 pt-5'>
        <Grid2 item size={{ xs: 12, md: 12, sm: 12, lg: 12 }}>
          <h1 className='mt-5' style={{ textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 65 }}>Build or improve your digital product with our one-stop team</h1>

        </Grid2>
      </Grid2>
      <Grid2 container className='justify-content-between w-100 align-items-start d-flex mt-5 pt-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Discovery Session Exploration & Strategy</h1>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div className=''>
            <div className='d-flex flex-wrap gap-5'>
              <p className='size22 weight500'>
              We begin by understanding your business, challenges, and goals. Together, we’ll outline a clear strategy tailored to your needs. If you're looking for impeccable product execution and stunning designs that captivate your audience—look no further, we're thrilled to connect with you!
              </p>

              <Grid2 container spacing={3} className="w-100" >
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>MVP Scope</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                  If you’re struggling to envision what your product should look like, we can help you define the core features and functionality for your Minimum Viable Product (MVP) 
                  </p>
                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Roadmap</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                  We offer a comprehensive project roadmap and a detailed cost estimate, ensuring complete transparency. This approach provides you with a clear understanding of the project's timeline and budget, empowering you to plan with confidence.
                  </p>
                </Grid2>
              </Grid2>
            </div>
          </div>
        </Grid2>
      </Grid2>

      <Grid2 container className='justify-content-between w-100 align-items-start d-flex mt-5 pt-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Development & Strategic Assessment</h1>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div className=''>
            <div className='d-flex flex-wrap gap-5'>
              <p className='size22 weight500'>
              Let’s collaborate to transform your vision into a fully functional digital product. We’ll begin by analyzing your business needs and aligning them with real user expectations. From there, we’ll craft a tailored implementation strategy to bring your idea to life effectively.
              </p>

              <Grid2 container spacing={3} className="w-100" >
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Research & Planning</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                  We will carry out comprehensive research to fully understand your ideas and requirements.
                  </p>

                  {
                    discovery_scopping.map((item) => (
                      <p className='size20 weight700 mt-1 border-bottom py-3'>{item}</p>
                    ))
                  }
                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>User Experience Research</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                  Our UX designers will employ a variety of advanced research techniques to gather valuable insights into your product’s needs, ensuring it meets both user expectations and business goals. This process includes:
                  </p>

                  {
                    ux_research.map((item) => (
                      <p className='size20 weight700 mt-1 border-bottom py-3'>{item}</p>
                    ))
                  }
                </Grid2>
              </Grid2>
            </div>
          </div>
        </Grid2>
      </Grid2>

      <Grid2 container className='justify-content-between w-100 align-items-start d-flex mt-5 pt-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Product Design</h1>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div className=''>
            <div className='d-flex flex-wrap gap-5'>
              <p className='size22 weight500'>
                Product design is where we bring your ideas to life. We will use our creative and technical skills to create an attractive digital product. With our functional and aesthetic touch, it’s bound to convert potential users into loyal customers.
              </p>

              <Grid2 container spacing={3} className="w-100" >
              <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Branding</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                    To capture users’ attention and retain them, you need a digital product that is not only functional, but also visually appealing.
                  </p>

                  {
                    ux_research.map((item) => (
                      <p className='size20 weight700 mt-1 border-bottom py-3'>{item}</p>
                    ))
                  }
                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>UX Design</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                  A UX Designer is going to outline the structure of your product. They will do so by designing a series of wireframes for each section and feature.
                  </p>

                  {
                    discovery_scopping.map((item) => (
                      <p className='size20 weight700 mt-1 border-bottom py-3'>{item}</p>
                    ))
                  }
                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Web & UI Design</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                  To capture users’ attention and retain them, you need a digital product that is not only functional, but also visually appealing.
                  </p>

                  {
                    ux_research.map((item) => (
                      <p className='size20 weight700 mt-1 border-bottom py-3'>{item}</p>
                    ))
                  }
                </Grid2>
                
              </Grid2>
            </div>
          </div>
        </Grid2>
      </Grid2>

      <Grid2 container className='justify-content-between w-100 align-items-start d-flex my-5 pt-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 5 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Web Development</h1>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 7 }}>
          <div className='mb-5'>
            <div className='d-flex flex-wrap gap-5'>
              <p className='size22 weight500'>
                At this stage, we’re going to make the visualized product concept come to life. We will choose the technology that suits it best. In addition, we will make sure that this technology is sustainable enough to allow for future improvements to your product.
              </p>
{/* 
              <Grid2 container spacing={3} className="w-100" >
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Front-end Development</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                  A UX Designer is going to outline the structure of your product. They will do so by designing a series of wireframes for each section and feature.
                  </p>

                  {
                    discovery_scopping.map((item) => (
                      <p className='size20 weight700 mt-1 border-bottom py-3'>{item}</p>
                    ))
                  }
                </Grid2>
                <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 6 }}>
                  <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Back-end Development</h1>

                  <p className='size20 weight500 mt-3 text-secondary'>
                  To capture users’ attention and retain them, you need a digital product that is not only functional, but also visually appealing.
                  </p>

                  {
                    ux_research.map((item) => (
                      <p className='size20 weight700 mt-1 border-bottom py-3'>{item}</p>
                    ))
                  }
                </Grid2>
              </Grid2> */}
            </div>
          </div>
        </Grid2>
      </Grid2>

      <Footer />

    </div>
  )
}

export default OurProcess
