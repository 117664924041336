// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB65_ioxEvQCOXOma0RYu9K7vtkd7sqlr4",
    authDomain: "madebyhumans.firebaseapp.com",
    projectId: "madebyhumans",
    storageBucket: "madebyhumans.firebasestorage.app",
    messagingSenderId: "198150733580",
    appId: "1:198150733580:web:899356b5bf69f2cc96d329",
    measurementId: "G-W7VPSCK7HY"
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);