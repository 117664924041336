import React from 'react'
import { Grid2 } from '@mui/material'
import { projects, projectsNew } from '../data/data'
import Footer from '../components/Footer'

const Project = () => {
  return (
    <div className='px-5 h-100'>
      <Grid2 container className=' justify-content-between align-items-center d-flex my-5 pt-5' >
        <Grid2 item size={{ xs: 12, md: 12, sm: 12, lg: 12 }} className="mt-5">
          <h1 style={{ textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 65, }}>Creating digital products your clients will fall in love with</h1>
        </Grid2>
      </Grid2>


      <Grid2 container className='justify-content-between w-100 align-items-start d-flex my-5 py-5'>
        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 4 }}>
          <h1 style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>Why our clients work with us</h1>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 8 }}>
          <div className=''>
            <div className='d-flex flex-wrap gap-5'>
              <Grid2 container spacing={5} className="w-100" >
                <Grid2 item size={{ xs: 12, md: 12, sm: 12, lg: 12 }}>
                  <p className='size20 weight500 mt-1 text-secondary'>
                    This is some of the projects we have been crafting lately for our clients. Cutting-edge, functional & beautiful. Take a look!
                  </p>
                </Grid2>

                {/* {
                  projects.map((item) => (
                    <Grid2 item size={{ xs: 12, md: 12, sm: 12, lg: 6 }}>
                      <div className=''>
                        <img
                          src={item.img}
                          className='w-100 text-center'
                        />

                        <p className='size14 weight500 mt-1 text-secondary'>{item.title}</p>
                        <p className='size22 weight600 '>
                          {item.desc}
                        </p>
                      </div>
                    </Grid2>
                  ))
                } */}

                {
                  projectsNew.map((e) => (
                    <Grid2 item size={{ xs: 12, md: 6, sm: 12, lg: 4 }}>
                      <div>
                        <h1 style={{ textAlign: 'left', textWrap: 'wrap', fontWeight: 600, fontSize: 75, }}>
                          {e.count}+
                        </h1>
                        <h1 className='text-secondary' style={{ maxWidth: 350, textAlign: 'left', textWrap: 'wrap', fontWeight: 700, marginBottom: 0, fontSize: 30 }}>
                          {e.title}
                        </h1>
                      </div>
                    </Grid2>
                  ))
                }
              </Grid2>
            </div>
          </div>
        </Grid2>
      </Grid2>

      <Footer />
    </div>
  )
}

export default Project
