import React from 'react'

const CustomInput = ({ label, ...rest }) => {
    return (
        <div className='my-5'>
            <div>
                <p className='size18 weight500 text-secodary'>
                    {label}
                </p>
            </div>
            <input type="text" className='form-input size22 weight500 text-black' {...rest} />
        </div>
    )
}

export default CustomInput