import React from 'react'
import { tabs } from '../data/data'
import { Facebook, FacebookOutlined, Instagram, Twitter, YouTube } from '@mui/icons-material'

const Footer = () => {
    return (
        <div className='d-flex py-5 justify-content-between align-items-start border-top flex-wrap gap-4'>
            <div className='flex-wrap'>
                <h1 className='size28 weight600'>Chat with Us</h1>
                <p className='size18 weight500 text-secondary mt-3'>
                    hello@example.com
                </p>
                <p className='size18 weight500 text-secondary'>
                    Schedule a call
                </p>
            </div>
            <div className='flex-wrap'>
                <h1 className='size28 weight600'>Follow Us</h1>
                <div className='d-flex gap-4 mt-3'>
                    <FacebookOutlined />
                    <Instagram />
                    <YouTube />
                    <Twitter />
                </div>
            </div>
            <div className='flex-wrap' style={{minWidth: 200}}>
                <h1 className='size28 weight600'>Website</h1>
                <p className='size18 weight500 text-secondary mt-3'>
                    Our Process
                </p>
                <p className='size18 weight500 text-secondary mt-3'>
                    About
                </p>
                <p className='size18 weight500 text-secondary mt-3'>
                    Our Team
                </p>
                <p className='size18 weight500 text-secondary mt-3'>
                    Projects
                </p>
            </div>
            {/* <div className='flex-wrap'>
                <h1 className='size28 weight600'>Find Us</h1>
                <p className='size18 weight500 text-secondary mt-3'>
                    1740 Broadway
                </p>
                <p className='size18 weight500 text-secondary'>
                    New York City 10019, United States
                </p>
            </div> */}
        </div>
    )
}

export default Footer
