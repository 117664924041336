import { Outlet } from "react-router-dom";
import React from 'react'
import Scrollbars from "react-custom-scrollbars-2";
import Header from "../components/Header";

const MainLayout = () => {
    return (
        <Scrollbars style={{ width: "100%", height: "100vh" }} autoHide={true}>
            <div>
                <Header />
            </div>
            <div className="w-100 h-100" >
                <Outlet />
            </div>
        </Scrollbars>
    )
}

export default MainLayout